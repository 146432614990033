import React from "react";
import { TextInput, StyleSheet } from "react-native";
import { COLORS, FONTS } from "../../styles/theme";

interface Props {
  onSubmit: (data: string) => void;
}

function Bar({ onSubmit }: Props) {
  const [value, onChangeText] = React.useState("");
  return (
    <TextInput
      onSubmitEditing={() => {
        onSubmit(value);
      }}
      onChangeText={(text) => onChangeText(text)}
      value={value}
      style={[styles.textInput]}
    />
  );
}

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: COLORS.BAR,
    borderRadius: 4,
    borderColor: COLORS.BLUE,
    borderWidth: 1,
    flex: 1,
    padding: 10,
    margin: 0,
    color: COLORS.WHITE,
    fontSize: FONTS.MESSAGE,
  },
});

export default Bar;
