import { ActivityIndicator, FlatList, Text, View } from "react-native";
import { useEffect, useState } from "react";
import Chat from "./Messages/chat";
import Bar from "./Messages/bar";

const MyList = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<string[]>([]);
  const [nextPage, setNextPage] = useState(
    "https://rickandmortyapi.com/api/character"
  );

  const fetchNextPage = async () => {
    return;
    if (loading) {
      return;
    }

    setLoading(true);
    const response = await fetch(nextPage);
    const responseJson = await response.json();

    setItems((existingItems) => {
      return [...responseJson.results, ...existingItems];
    });
    setNextPage(responseJson.info.next);

    setLoading(false);
  };

  useEffect(() => {
    fetchNextPage();
  }, []);

  return (
    <FlatList
      data={items}
      renderItem={({ item }) => <Chat text={item} sender={true}></Chat>}
      contentContainerStyle={{ gap: 10, alignItems: "stretch", padding: 20 }}
      onEndReached={fetchNextPage}
      //onEndReachedThreshold={3}
      ListHeaderComponent={() => (
        <Bar
          onSubmit={(data: string) => {
            setItems((existingItems) => {
              return [data, ...existingItems];
            });
          }}
        ></Bar>
      )}
      ListFooterComponent={() => loading && <ActivityIndicator />}
      inverted
    />
  );
};

export default MyList;
