import React from "react";
import "../../styles/styles.css";
import { View, Text, StyleSheet } from "react-native";
import { COLORS } from "../../styles/theme";

interface Props {
  text: String;
  sender: boolean;
}

function Chat({ text, sender }: Props) {
  return (
    <View
      style={[
        styles.container,
        sender ? styles.rightAlign : styles.leftAlign,
        { backgroundColor: sender ? COLORS.BLUE : COLORS.GRAY },
      ]}
    >
      <Text style={styles.text}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignSelf: "flex-start",
    padding: 10,
    borderRadius: 15,
    margin: 0,
  },
  leftAlign: {
    alignSelf: "flex-start",
  },
  rightAlign: {
    alignSelf: "flex-end",
  },
  text: {
    color: COLORS.WHITE,
  },
});

export default Chat;
