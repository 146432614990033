export const COLORS = {
  BLUE: "#2492EE",
  GREY: "#2B2B2B",
  BLACK: "#1F1F1F",
  WHITE: "#FFFFFF",
  GRAY: "#434246",
  BAR: "#424242",
};

export const FONTS = {
  MESSAGE: 18,
};
